import "./App.css";
import NavBar from "./ui/layout/NavBar";
import Header from "./ui/home/Header";
import HomeCards from "./ui/home/ChooseMe/HomeCards";
import Footer from "./ui/layout/Footer";
import HireMeSection from "./ui/home/HireMe/HireMeSection";
import ProjectsView from "./ui/home/Projects/ProjectsView";
function App() {
  return (
    <div>
      <NavBar />
      <div className="initialLoad">
        <div className="container">
          <Header />
          <HomeCards />
          <ProjectsView />
        </div>
      </div>
      {/* 

      I am going to start the companies worked with banner
      - first figure out how it looks in general add a logo or two
        - research online for examples portfolio or just in general
        - if there was one that highlighted some companies with like size
        - think about background and how logos look on it
      - then make a complete list of all companies and find all logos


going to follow this
https://ishadeed.com/article/aligning-logos-css/

Examples of logo banners

https://www.airtable.com/
- #1 so far
- negative not too many companies - but i could double up


https://www.knack.com/customers
- basic and big

https://www.zacoustic.com/
- basic and only 5 companies

https://www.workboard.com/
- its an image!!! very basic no size just white background


Resource https://www.designveloper.com/projects/
- this has a good way to show projects and like counts of hours worked and shit

https://www.accenture.com/us-en
- 2 different carasoul of projects
- timeline like i could do my resume

https://www.workday.com/ /https://www.workday.com/en-us/customer-stories.html
- simple project carousol


Title: The real reason schools in America suck. Who would teach if they didn't?
subtitle: If we consider whats missing in our schools like real life skils (shop, cooking, personal finance), critical thinking and problem solving, emotional intelligence then we realize most people would be student.Where do we find the teachers

    `TODOs
      - Fix footer, not responsive
      - modal: read more opens ip highlights that link to different parts of the website
      like highlight the qa and a and projects
      - Meta tags: copy other sites, so mine can show up first and
      like a description of me?
      - component: logos for all companies i worked for or projects on
        this includes bam, social solutions, cms stuff, hud, 
        the leading age stuff, nation geographic, air force
      - component: my values like for companies
    
    tofilio type cards- pictires and text based on the projects
      i want , uship micro, youniv/upmark/hopkins, knack scaling problem, IAP, intranet national
      *ideally here i could have query strings that make either things diff visible or 
      go to a specific project
       ARE PROJECTS modals?Modals connected to query strings!!
      - component: Q & A questions- basically an application from 
      are you citizen too how many year sof experience on tihs to what
      size companies have you worked for? product? which industries?
      what industries are you interested in? 
      What technologies stacks could you be an IC or could you lead?
      - component: education. i could put some more details, formerly known and
      - component: references. i could give the following people as references upon request
      - contact us modal?


    future ideas:
    - meta tags
    - ai feature
    - linked in and twitter feeds?
    - recommendations
    - whats new page or cripser?

  

 */}
      <div className="hireMe" hidden>
        <HireMeSection />
      </div>

      <Footer />
    </div>
  );
}

export default App;

/*
Next:
- footer
- once i start working on more content maybe do a branch


*/
