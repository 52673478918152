function ProjectCard({
  cardBackground,
  cardUrl,
  mainTitle,
  subTitle,
  cardInfo,
  titleColor,
}) {
  const colorCode = titleColor === "black" ? "#232427" : "#fff";
  const styles = {
    backgroundImage: `url(${cardBackground}) `,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  const colorStyle = {
    color: `${colorCode}`,
  };

  return (
    <div className="cardHTML">
      <div className="cardBody">
        <div style={styles} className="blog-card spring-fever">
          <div className="title-content">
            <h3>
              <a
                style={colorStyle}
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/ilemberg/"
              >
                {mainTitle}
              </a>
            </h3>
            <div style={colorStyle} className="intro">
              {subTitle}
            </div>
          </div>
          <div className="card-info">
            <div>{cardInfo}</div>

            <a target="_blank" rel="noreferrer" href={cardUrl}>
              Learn More<span className="licon icon-arr icon-black"></span>
            </a>
          </div>

          <div className="gradient-overlay"></div>
          <div className="color-overlay"></div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
