import Card from "./Card";

function HomeCards() {
  return (
    <div className="homeBody">
      <div className="container">
        <Card
          number="🧑‍💼"
          title="Hire Me"
          description="I am ready to lead your engineering teams. Find out why."
          link="https://www.linkedin.com/in/ilemberg/"
        />
        <Card
          number="👩‍👦‍👦"
          title="Partner with Me"
          description="Are you looking for a technical co-founder with startup experience?"
          link="https://www.linkedin.com/in/ilemberg/"
        />
        <Card
          number="👀"
          title="Check Me Out"
          description="What is this guy all about? Would he be a good addition to your team?"
          link="https://www.linkedin.com/in/ilemberg/"
        />
      </div>
    </div>
  );
}

export default HomeCards;
