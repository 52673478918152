function Card({ number, title, description, link }) {
  return (
    <div className="homeCard">
      <div className="box">
        <div className="content">
          <h2>{number}</h2>
          <h3>{title}</h3>
          <p>{description}</p>
          <a href={link}>Read More</a>
        </div>
      </div>
    </div>
  );
}

export default Card;
