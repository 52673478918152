import "./Project.css";
//import "./Project-old.css";
import ProjectCard from "./ProjectCard";
import stackupBlur from "../../../images/stackupblur.png";
import younivblur from "../../../images/younivblur.jpg";
import ushipblur from "../../../images/ushipblur.jpg";
import ngblur from "../../../images/ngblur.png";
import knackblur from "../../../images/knackblur.jpg";
import zacouticblur from "../../../images/zacousticblur.jpg";
/*
TODOS NOW
- dislay values
- change the color of the titles of these cards, maybe ask chatgpt
- how does this page flow togher its just like label and more cards
  - spacing?
  - ask chatgpt
- add cards zacoustic and knack, they can be logo pics?
- all the links will beocme a modal
  - either modal or somewhere else ont he page
*/
function ProjectsView() {
  return (
    <>
      <h1>Career Highlights</h1>
      <div className="mainProjectsDiv">
        <ProjectCard
          mainTitle="Director of Engineering @ Pluralsight"
          subTitle="Native Apps"
          cardBackground={stackupBlur}
          cardUrl={"https://www.pluralsight.com/product/downloads"}
          cardInfo="Lead three teams in implementing features on
              native and mobile platforms such as video streaming, custom gamification, offline and other
              features while maintaining over a 4.5 rating on Android and Apple
              "
          titleColor="white"
        />
        <ProjectCard
          mainTitle="Launched B2C SaaS Startup @ Youniv"
          subTitle="Co-founder"
          cardBackground={younivblur}
          cardUrl={"https://twitter.com/younivofficial"}
          cardInfo=" Led the design, development, launch, and maintenance of a social, data-driven web application for Johns Hopkins University, working with a distributed cross-functional team of developers, scientists, and graduate students"
          titleColor="black"
        />
        <ProjectCard
          mainTitle="First Microservice in Production @ uShip"
          subTitle="Team Lead Senior Engineer"
          cardBackground={ushipblur}
          cardUrl={
            "https://www.uship.com/blog/tips/inboxes-rejoice-saved-search-daily-emails-are-back/"
          }
          cardInfo="Increased customer engagement by architecting and developing a transactional email microservice that utilized a third-party email provider to send over 10 million emails a month"
          titleColor="black"
        />
        <ProjectCard
          mainTitle="2014 Award for World's 10 Best Intranets @ Celerity"
          subTitle="Software Engineer"
          cardBackground={ngblur}
          cardUrl={
            "https://www.prnewswire.com/news-releases/national-geographics-socially-charged-intranet-powered-by-celerity-wins-nielsen-norman-group-award-for-worlds-10-best-intranets-239616091.html"
          }
          cardInfo="Played a key role in designing, developing, and launching a cutting-edge top rated social intranet for the National Geographic Society"
          titleColor="white"
        />
        <ProjectCard
          mainTitle="Improved SaaS Startup Performance by 30% @ Knack"
          subTitle="Engineering Lead"
          cardBackground={knackblur}
          cardUrl={"https://www.knack.com/"}
          cardInfo="Improved average app performance by over 30%  and increased infrastructure reliability by migrating databases, taking advantage of cloud services, and converting to Infrastructure as Code"
          titleColor="white"
        />
        <ProjectCard
          mainTitle="Scaled B2B SaaS Startup @ Zacoustic"
          subTitle="Head of Technology"
          cardBackground={zacouticblur}
          cardUrl={
            "https://www.prnewswire.com/news-releases/national-geographics-socially-charged-intranet-powered-by-celerity-wins-nielsen-norman-group-award-for-worlds-10-best-intranets-239616091.html"
          }
          cardInfo="Led a distributed engineering team in scaling a prototype to a highly stable and performant multi-million dollar annual revenue B2B SaaS product"
          titleColor="black"
        />
      </div>
    </>
  );
}

export default ProjectsView;

/*card todos
        - use a project get the right text and pic for one
        - what happens on click? modal?
        - make sure to pass all info from project view
      
      
      
      */
