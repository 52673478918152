function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="socialLinks">
        <a href="/">©{currentYear}</a>
        <a href="/">ilyalemberg.com</a>
        <a href="/">
          <i className="fas fa-home"></i>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/ilemberg/"
        >
          <i className="fab fa-linkedin"></i>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/waitingfortoday"
        >
          <i className="fab fa-twitter"></i>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://drive.google.com/file/d/1MVy6eLlck_5HqHMIojoy_cSOqGlZvnpX/view?usp=sharing"
        >
          <i className="fas fa-file-alt"></i>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="mailto:ilyaplemberg@gmail.com"
        >
          <i className="fas fa-envelope"></i>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
