import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

//import AppLive from "./App-live";

const root = ReactDOM.createRoot(document.getElementById("root"));
//const host = window.location.hostname;
//const isLocal = host === "localhost" ? true : false;
//console.log("Current host: " + host);
root.render(
  // <React.StrictMode>{isLocal ? <App /> : <AppLive />} ;</React.StrictMode>
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
