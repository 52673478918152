function NavBar() {
  return (
    <nav className="navbar">
      <div className="navbar-left">
        <a href="/">
          <img src="/favicon-me.ico" alt="profile" />
        </a>
      </div>

      <div className="container">
        <ul>
          <li>
            <a href="/">
              <i className="fas fa-home"></i>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/ilemberg/"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/waitingfortoday"
            >
              <i className="fab fa-twitter"></i>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://drive.google.com/file/d/1MVy6eLlck_5HqHMIojoy_cSOqGlZvnpX/view?usp=sharing"
            >
              <i className="fas fa-file-alt"></i>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:ilyaplemberg@gmail.com"
            >
              <i className="fas fa-envelope"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
