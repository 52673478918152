function Header() {
  return (
    <div className="header">
      <div className="container">
        <div className="headerText">
          <h1>Hi 👋, I'm Ilya Lemberg </h1>
          <h2>
            an engineer who really loves leading happy 🤓 product teams that
            build things we are proud of.
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Header;
